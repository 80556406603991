@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
::selection {
  color: #202020;
  background: #fff;
}
.wrapper {
  max-width: 1920px;
  margin: 0px 50px;
  padding: 30px 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #202020;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row {
  display: flex;
  margin: 0px 0;
}
.wrapper .row-1 {
  justify-content: flex-start;
}
.wrapper .row-2 {
  justify-content: flex-end;
}
.wrapper .row section {
  background: #202020;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .row section::before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  background: #202020;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.row-1 section::before {
  right: -7px;
}
.row-2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #202020;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #3ea0e2;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.row-1 section .icon {
  top: 15px;
  right: -60px;
}
.row-2 section .icon {
  top: 15px;
  left: -60px;
}
.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}
.row section p {
  margin: 10px 0 17px 0;
  color: #fff;
}
.row section .bottom a:hover {
  transform: scale(0.97);
}
@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row-1 section::before {
    left: -7px;
  }
  .row-1 section .icon {
    left: -60px;
  }
}
@media (max-width: 440px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
}
