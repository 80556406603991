.countdown-timer {
  width: 100%;
  height: 60px;
  font-size: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: solid 2px #fff;
  border-radius: 15px 0 15px 0;
  margin: 10px 0;


}
.countdown-timer span {
  margin: 0 5px;
}
@media screen {
  @media (max-width: 768px) {
    .countdown-timer {
      font-size: 12px;
    }
    .countdown-timer span {
      margin: 0 2px;
    }
  }
}
