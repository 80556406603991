body.active-modal {
    overflow-y: hidden;
}
.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}
.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #202020;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    background-color: lightgreen;
    border-radius: 3px;
    cursor: pointer;
}