@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
.services .Header {
  display: flex;
  width: 100%;
  height: 50 px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-family: 'poppins', sans-serif;
}
@media screen {
  @media (max-width: 48em) {
    .services .Header {
      margin-top: 30px;
      font-size: 20px;
    }
    .services .cards{
      flex-direction: column;
      margin: 5em 0 4em 0;
      flex-basis: 25%;
    }
    .services .card{
      margin-bottom: 40px;
    }
    #services{
      height: 150vh;
    }
  }
}
.services {
  background-color: transparent;
  color: #fff;
  margin: 0 50px;
  justify-content: center;
  align-items: center;
}
.services .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin: 8em 0 4em 0;
}

.services .card {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  padding: 1em;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  background: rgba(127, 127, 127, 0.25);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: relative;
}
.services .card img {
  width: 8em;
  margin: -4em 0 1em 0;
  align-self: center;
}

.services .card .light {
  background: #48fc8b;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  position: absolute;
  top: -2em;
  left: 50%;
  transform: translate(-50%);
  filter: blur(15px);
  z-index: -1;
}
.services h3 {
  margin: 10px 0;
}
.services p{
  line-height: 1.5rem;
}

.services .card:nth-child(1) .light {
  background: #ffdb28;

}

.services .card:nth-child(2) .light {
  background: #6e3dbc;

}

.services .card:nth-child(3) .light {
  background: #F29f05;

}
.services .card:nth-child(1){
  flex-grow: 1;
}
.services .card:nth-child(2){
  flex-grow: 1;
}
.services .card:nth-child(3){
  flex-grow: 1;
}
