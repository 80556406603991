.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}

.socials li{
    margin: 0 10px;
}

.socials a{
    text-decoration: none;
    color: #fff;
    padding: 5px;

}
.footer-bottom{
    display: flex;
        align-items: center;
        justify-content: center;
}
.footer-bottom p{
    float: left;
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
 }
 .footer-bottom p a{
   color:#44bae8;
   font-size: 16px;
   text-decoration: none;
 }
 .footer-bottom span{
     text-transform: uppercase;
     opacity: .4;
     font-weight: 200;
 }
 @media (max-width:500px) {
    .footer-menu ul{
      display: flex;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .footer-bottom{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    }